import React, { useState } from 'react'
import { useStyles } from '../../helper';
import { Box, Grid, Stack, Typography } from '@mui/material';
import CommonInput from '../../components/common/Input';
import CommonButton from '../../components/common/Button';
import { FaRegUser } from 'react-icons/fa';
import { MdOutlineModeEditOutline } from 'react-icons/md';
const Profile = () => {
  const classes = useStyles();
  const [edit, setEdit] = useState();
  const handleClick = () => setEdit((show) => !show);


  const [file, setFile] = useState();

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    // convert e.target.files to  base 64 string
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      console.log(reader.result);
      setFile(reader.result);
    };

  }

  return (
    <>
      <Typography fontSize={'20px'} fontWeight={600} marginBottom={2}>{edit ? 'Edit Profile' : 'Profile Details'}</Typography>
      <Box className={classes.cardContainer} sx={{ padding: { xs: 3, sm: 5 } }}>
        <Grid container gap={{ xs: '30px', sm: '50px' }} justifyContent={'space-between'} sx={{ maxWidth: '1200px', margin: 'auto' }}>
          <Grid item xs={12} sm={2.5}>
            <Grid container sx={{ width: { xs: '100%', sm: '200px' } }}>
              <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                {
                  !edit ?
                    <Box sx={{ width: '110px', height: '110px', background: '#f1f1f1', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {
                        file ?
                          <img src={file} width={110} height={110} style={{ borderRadius: '50%' }} />
                          :
                          <FaRegUser style={{ fontSize: '50px', color: '#00000091' }} />
                      }
                    </Box>
                    :
                    <Box sx={{ width: '110px', height: '110px', background: '#f1f1f1', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                      {
                        file ?
                          <img src={file} width={110} height={110} style={{ borderRadius: '50%' }} />
                          :
                          <FaRegUser style={{ fontSize: '50px', color: '#00000091' }} />
                      }
                      <Box sx={{ position: 'absolute', cursor: 'pointer', bottom: '14px', right: '0px', borderRadius: '50%' }}>
                        <Box sx={{ position: 'relative', width: '22px', height: '22px' }}>
                          <MdOutlineModeEditOutline style={{ position: 'relative', background: '#000', color: '#fff', fontSize: '22px', padding: '4px', borderRadius: '50%' }} />
                          <input type="file" onChange={handleChange} style={{ position: 'absolute', opacity: '0', width: '100%', height: '100%', right: '0' }} />
                        </Box>
                      </Box>
                    </Box>
                }

              </Grid>
              <Grid item xs={12} display={'flex'} justifyContent={'center'} marginTop={2}>
                <Typography fontSize={'20px'} fontWeight={700}>Rishikesh Sanjiv</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8.5}>
            <Grid container gap={3} justifyContent={'space-between'}>
              <Grid item xs={12} sm={5.5}>
                <CommonInput value='James' labelInput label='First Name' disabled={edit ? false : true} />
              </Grid>
              <Grid item xs={12} sm={5.5}>
                <CommonInput value='Franklin' labelInput label='Last Name' disabled={edit ? false : true} />
              </Grid>
              <Grid item xs={12} sm={5.5}>
                <CommonInput value='Jamesfarnkline@gmail.com' labelInput label='Email' disabled={edit ? false : true} />
              </Grid>
              <Grid item xs={12} sm={5.5}>
                <CommonInput value='Time Square, New York, USA ' labelInput label='Location' disabled={edit ? false : true} />
              </Grid>
              <Grid item xs={12} sm={5.5}>
                <CommonInput value='+91 4546848560' labelInput label='Phone Number' disabled={edit ? false : true} />
              </Grid>
              <Grid item xs={12} sm={5.5}>
                <CommonInput value='+91 4546848560' labelInput label='Whatsapp Number' disabled={edit ? false : true} />
              </Grid>
              <Grid item xs={12} sm={5.5}>
                <CommonInput value='Admin' labelInput label='Role' disabled={edit ? false : true} />
              </Grid>
              <Grid item xs={12} sm={5.5}>
                <CommonInput value='Active' labelInput label='Status' disabled={edit ? false : true} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign={'end'}>
            {
              edit ?
                <Stack gap={2} flexDirection={'row'} justifyContent={'flex-end'}>
                  <CommonButton buttonName='Cancel' onClick={handleClick} />
                  <CommonButton buttonName='Save' onClick={handleClick} />
                </Stack>
                :
                <CommonButton buttonName='Edit' onClick={handleClick} />
            }
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Profile