import React from 'react'
import { Box, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { CloseIcon } from '../../helper/Icons'
import CommonButton from '../../components/common/Button'
import { useNavigate } from 'react-router-dom'
import { Check } from '../../helper/Constants'

const ExportSuccess = ({ handleClose }) => {
  const navigate = useNavigate();
  return (
    <div>
      <DialogContent>
        <Grid container gap={2} width={{ xs: '250px', sm: '400px' }} paddingY={1}>
          <Grid item xs={12} display={'flex'} justifyContent={'center'}>
            <Box sx={{ background: '#f1f1f1', borderRadius: '50%', padding: '15px', width: '100px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={Check} />
            </Box>
          </Grid>
          <Grid item xs={12} marginBottom={'20px'}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography fontWeight={'500'}>Export Successfully</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <CommonButton buttonName="Done" fullWidth onClick={handleClose}/>
          </Grid>
        </Grid>
      </DialogContent>
    </div>
  )
}

export default ExportSuccess