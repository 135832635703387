import React, { useState } from 'react'
import { Box, DialogContent, DialogTitle, Grid, IconButton, Stack, Typography } from '@mui/material'
import { CloseIcon } from '../../helper/Icons'
import CommonButton from '../../components/common/Button'
import CommonModal from '../../components/common/Modal'
import { SecurityCamera } from '../../helper/Constants'

const ViewDevice = ({ handleClose, modalOpen }) => {
    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
        isEdit: ""
    });
    return (
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                Details of Device
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid container gap={2} width={{ xs: '250px', sm: '400px' }} paddingY={1}>
                    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                        <Box sx={{ background: '#000', borderRadius: '50%', padding: '15px', width: '100px', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={SecurityCamera} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ background: '#f1f1f1', borderRadius: '15px', padding: '20px' }}>
                            <Grid container paddingBottom={1}>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={'13px'} fontWeight={600}>Name :</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={'13px'}>Entrance</Typography>
                                </Grid>
                            </Grid>
                            <Grid container paddingBottom={1}>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={'13px'} fontWeight={600}>Project Name :</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={'13px'}>Project 1</Typography>
                                </Grid>
                            </Grid>
                            <Grid container paddingBottom={1}>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={'13px'} fontWeight={600}>RSTP Link :</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={'13px'}>user.ai/ppedetection</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <CommonButton buttonName="Edit Device"
                            onClick={() =>
                                setIsModalOpen({
                                    open: true,
                                    currentComponent: "addDevice",
                                    isEdit: true
                                })
                            } fullWidth />
                    </Grid>
                </Grid>
            </DialogContent>
            <CommonModal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
                } />
        </div>
    )
}

export default ViewDevice