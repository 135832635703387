import React from 'react'
import TextField from "@mui/material/TextField";
import { Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
const CommonInput = (props) => {
    const { type, placeholder, passwordInput, labelInput, label, value, disabled } = props;
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    return (
        <>
            {labelInput &&
                <Typography fontSize={'14px'} fontWeight={600} marginBottom={'6px'}>{label}</Typography>
               
            }
            {passwordInput ?
                <div className='p_relative'>
                    <TextField
                        fullWidth
                        hiddenLabel
                        disabled={disabled}
                        placeholder={placeholder}
                        value={value}
                        type={showPassword ? 'text' : 'password'}
                    />
                    <InputAdornment position="end" className="eye_btn">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                </div>
                :
                <TextField
                    fullWidth
                    hiddenLabel
                    disabled={disabled}
                    placeholder={placeholder}
                    value={value}
                    type={type || 'text'}
                />
            }

        </>
    )
}

export default CommonInput