import React, { useState } from 'react'
import { Pagination, Paper, Stack, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Grid } from '@mui/material';
import { StyledTableCell, StyledChip, StyledTableRow, useStyles, getAlertStatus, } from '../../helper';
import { Camera1, Whatsapp } from '../../helper/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import CommonModal from '../../components/common/Modal';
const AlertTable = () => {
    const classes = useStyles();
    var status = getAlertStatus(true);
    const navigate = useNavigate();
    const location = useLocation()

    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
    });
    return (
        <>
            <TableContainer component={Paper} className={classes.cardContainer}>
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                Image
                            </StyledTableCell>
                            <StyledTableCell>
                                Alert Type
                            </StyledTableCell>
                            <StyledTableCell>
                                Project Name
                            </StyledTableCell>
                            <StyledTableCell>
                                Time & Date
                            </StyledTableCell>
                            <StyledTableCell>
                                <Grid container justifyContent={'center'} alignItems={'center'} gap={1} flexWrap={'nowrap'}>
                                    <Grid item>
                                        <img src={Whatsapp} />
                                    </Grid>
                                    <Grid item textAlign={'start'}>
                                        WhatsApp <br /> Notification Status
                                    </Grid>
                                </Grid>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[1, 1, 1, 1].map(() => {
                            return (
                                <StyledTableRow
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}
                                    onClick={() => navigate('/alert/details')}
                                >
                                    <TableCell component="th" scope="row">
                                        <img src={Camera1} width={60} />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        Helmet  No
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        Project 1
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        28th Aug at 12:30 PM
                                    </TableCell>
                                    <TableCell component="th" scope="row"
                                        onClick={(e) => { e.stopPropagation() }}
                                    >
                                        <StyledChip label={status?.title} sx={status?.styles} onClick={() =>
                                            setIsModalOpen({
                                                open: true,
                                                currentComponent: "changeStatus",
                                            })
                                        } />
                                    </TableCell>
                                </StyledTableRow>
                            )
                        })}
                        {/* <TableRow>
              <TableCell
                sx={{
                  color: "silver",
                  textAlign: "center",
                  paddingTop: "90px",
                  borderBottom: "none",
                  fontSize: "30px",
                }}
                colSpan="6"
              >
                No records to display
              </TableCell>
            </TableRow> */}
                    </TableBody>
                </Table>
            </TableContainer>
            {location.pathname == '/dashboard' ? '' :
                <Stack
                    sx={{ mt: '10px', pb: 2, fontSize: '14px' }}
                    flexDirection="row"
                    justifyContent="space-between"
                >
                    <div>
                        Showing{" "}
                        1{" "}
                        of 1 Results
                    </div>
                    {/* <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        /> */}
                </Stack>
            }

            <CommonModal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "" })
                } />
        </>
    )
}

export default AlertTable