import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';

export default function CommonSelect(props) {
    const { selectHead, selectList, labelSelect, label, style,className } = props;

    const [selectValue, setSelectValue] = React.useState('Select');

    const handleChange = (event) => {
        setSelectValue(event.target.value);
    };

    return (
        <>
            {labelSelect ?
                <Typography fontSize={'14px'} fontWeight={600} marginBottom={'6px'}>{label}</Typography>
                : ''
            }
            <Select
                value={selectValue}
                onChange={handleChange}
                displayEmpty
                fullWidth
                sx={style}
                className={className}
            >
                {/* <MenuItem value="" hidden selected>{selectHead}</MenuItem> */}
                {
                    selectList.map((data, i) => {
                        return (
                            <MenuItem value={data.value} key={i}>{data.name}</MenuItem>
                        )
                    })
                }
            </Select>
        </>
    );
}