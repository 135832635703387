import React from 'react'
import { Logo_black } from '../../helper/Constants'
import { Box, Checkbox, Grid, Typography } from '@mui/material'
import CommonInput from '../../components/common/Input'
import CommonButton from '../../components/common/Button'
import { Link, useNavigate } from 'react-router-dom'

const Login = () => {
    const navigation = useNavigate();

    return (
        <>
            <div>
                <Box display={'flex'} alignItems={'center'} padding={1}>
                    <img src={Logo_black} width={'160px'} />
                </Box>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'calc(100vh - 75px)'}>
                    <Box sx={{ background: '#f1f1f1', margin: '0 20px' }} maxWidth={'800px'} width={'100%'} borderRadius={'25px'} padding={{ xs: '40px 20px', sm: '70px' }}>
                        <Grid container gap={'20px'} maxWidth={'400px'} width={'100%'} margin={'auto'}>
                            <Grid item xs={12}>
                                <Typography fontSize={'30px'} fontWeight={'500'} textAlign={'center'} marginBottom={'20px'}>Log In</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <CommonInput placeholder='Email' type='email' />
                            </Grid>
                            <Grid item xs={12}>
                                <CommonInput placeholder='Password' passwordInput />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container sx={{ justifyContent: 'space-between' }}>
                                    <Grid item sx={6}>
                                        <Grid container sx={{ alignItems: 'center' }}>
                                            <Grid item sx={6} className=''>
                                                <Checkbox
                                                    defaultChecked
                                                    sx={{
                                                        padding: '0',
                                                        color: 'var(--primary)',
                                                        '& .MuiSvgIcon-root': { fontSize: 28 },
                                                        '&.Mui-checked': {
                                                            color: 'var(--primary)',
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sx={6} className='login_para'>Remember me</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sx={6} className='login_para'><Link to='/forgot-password'>Forget Password?</Link></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <CommonButton buttonName='Submit' fullWidth onClick={() => navigation('/camera')} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography textAlign={'center'}>Don’t have an Account? <Link to='/signup'> Sign Up</Link></Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </>
    )
}

export default Login