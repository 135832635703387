import React, { useState } from 'react'
import { Pagination, Paper, Stack, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Chip, TextField } from '@mui/material';
import { StyledTableCell, StyledChip, StyledTableRow, useStyles, getStatus } from '../../helper';
import { Device_Camera } from '../../helper/Constants';
import CommonButton from '../../components/common/Button';
import CommonModal from '../../components/common/Modal';
import { GrRefresh } from 'react-icons/gr';
// import FilterDevice from './FilterDevice';
import { VscSettings } from 'react-icons/vsc';

const Phase = () => {
  const classes = useStyles();
  var status = getStatus(true);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    isEdit: ""
  });
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  return (
    <>
      <Stack direction="row" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: '12px' }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px', alignItems: { xs: 'end', sm: 'center' } }}>
        <TextField hiddenLabel className='search_field' placeholder='Search' sx={{ width: { xs: '100%', sm: 'unset' } }} />
        <Stack flexDirection={'row'} alignItems={'center'} gap={'10px'}>
          <CommonButton buttonName="Add Phase"
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "addPhase",
                isEdit: false
              })
            } />
          <VscSettings style={{ fontSize: '20px', cursor: 'pointer' }} onClick={toggleDrawer} />
          <GrRefresh style={{ fontSize: '20px', cursor: 'pointer' }} />
          {/* <FilterDevice openDrawer={openDrawer} toggleDrawer={toggleDrawer} /> */}
        </Stack>
      </Stack>
      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
              Phase Incharge
              </StyledTableCell>
              <StyledTableCell>
                Project Name
              </StyledTableCell>
              <StyledTableCell>
                Phase Name
              </StyledTableCell>
              <StyledTableCell>
                Phase Number
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 1, 1, 1].map(() => {
              return (
                <StyledTableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}
                  onClick={() =>
                    setIsModalOpen({
                      open: true,
                      currentComponent: "viewPhase",
                    })
                  }
                >
                  <TableCell component="th" scope="row">
                    Phase User 1
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Project 1
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Phase Name 1
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Phase Number 1
                  </TableCell>
                </StyledTableRow>
              )
            })}
            {/* <TableRow>
              <TableCell
                sx={{
                  color: "silver",
                  textAlign: "center",
                  paddingTop: "90px",
                  borderBottom: "none",
                  fontSize: "30px",
                }}
                colSpan="6"
              >
                No records to display
              </TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        sx={{ mt: '10px', pb: 2, fontSize: '14px' }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          1{" "}
          of 1 Results
        </div>
        {/* <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        /> */}
      </Stack>

      <CommonModal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
        } />
    </>
  )
}

export default Phase