import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Camera from '../../pages/camera/Camera';
import Device from '../../pages/device/Device';
import Dashboard from '../../pages/dashboard/Dashboard';
import Alert from '../../pages/alert/Alert';
import Report from '../../pages/report/Report';
import Profile from '../../pages/profile/Profile';
import AlertDetails from '../../pages/alert/AlertDetails';
import Projects from '../../pages/projects/Projects';
import Phase from '../../pages/phase/Phase';
import Towers from '../../pages/towers/Towers';

const Main = () => {
  const pages = [
    { name: 'Cameras', path: '/camera', element: <Camera /> },
    { name: 'Devices', path: '/device', element: <Device /> },
    { name: 'Projects', path: '/projects', element: <Projects /> },
    { name: 'Phase', path: '/phase', element: <Phase /> },
    { name: 'Towers', path: '/towers', element: <Towers /> },
    { name: 'Dashboard', path: '/dashboard', element: <Dashboard /> },
    { name: 'Alert', path: '/alert', element: <Alert /> },
    { name: 'Alert', path: '/alert/details', element: <AlertDetails /> },
    { name: 'Report', path: '/report', element: <Report /> },
    { name: 'Profile', path: '/profile', element: <Profile /> },
  ]

  return (
    <Routes>
      {pages.map((page, index) => {
        const { name, path, element } = page;
        return (
          <Route key={index} exact path={path} element={element} />
        )
      })}
    </Routes>
  )
}

export default Main