import React, { useState } from 'react'
import { Box, DialogContent, DialogTitle, Divider, Grid, IconButton, Stack, Typography } from '@mui/material'
import { CloseIcon } from '../../helper/Icons'
import CommonButton from '../../components/common/Button'
import CommonModal from '../../components/common/Modal'
import { SecurityCamera } from '../../helper/Constants'

const ViewPhase = ({ handleClose, modalOpen }) => {
    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
        isEdit: ""
    });
    return (
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                Details of Phase
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid container gap={2} width={{ xs: '250px', sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <Box sx={{ background: '#f1f1f1', borderRadius: '15px', padding: '20px' }}>
                            <Grid container paddingBottom={1}>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={'13px'} fontWeight={600}>Phase User :</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={'13px'}>Phase User 1</Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{marginBottom:1}}/>
                            <Grid container paddingBottom={1}>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={'13px'} fontWeight={600}>Project Name :</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={'13px'}>Project 1</Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{marginBottom:1}}/>
                            <Grid container paddingBottom={1}>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={'13px'} fontWeight={600}>Phase Name :</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={'13px'}>Phase Name 1</Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{marginBottom:1}}/>
                            <Grid container paddingBottom={1}>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={'13px'} fontWeight={600}>Phase Number :</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography fontSize={'13px'}>Phase Number 1</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <CommonButton buttonName="Edit Phase"
                            onClick={() =>
                                setIsModalOpen({
                                    open: true,
                                    currentComponent: "addPhase",
                                    isEdit: true
                                })
                            } fullWidth />
                    </Grid>
                </Grid>
            </DialogContent>
            <CommonModal
                modalOpen={isModalOpen}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "", isEdit: "" })
                } />
        </div>
    )
}

export default ViewPhase