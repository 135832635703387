import React from 'react'
import { Button } from '@mui/material'

const CommonButton = (props) => {
	const { onClick, buttonName, buttonColor,fullWidth } = props
	return (
		<>
			<Button variant="contained" color={buttonColor} onClick={onClick} fullWidth={fullWidth}>
				{buttonName}
			</Button>
		</>
	)
}

export default CommonButton