import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { CloseIcon } from '../../helper/Icons'
import CommonButton from '../../components/common/Button'
import { useNavigate } from 'react-router-dom'

const ChangeStatus = ({ handleClose }) => {
    const navigate = useNavigate();
    return (
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                Change Status
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Typography>Do you want to Change WhatsApp Notification Status? </Typography>
            </DialogContent>
            <DialogActions>
                <CommonButton buttonName='No' buttonColor='gray' onClick={handleClose} />
                <CommonButton buttonName='Yes' onClick={handleClose} />
            </DialogActions>
        </div>
    )
}

export default ChangeStatus