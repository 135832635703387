import React from 'react'
import { Drawer, Grid, Typography, Divider, Stack, IconButton } from '@mui/material';
import CommonButton from '../../components/common/Button';
import CommonSelect from '../../components/common/Select';
import { CloseIcon } from '../../helper/Icons'
import DateFilter from '../../components/common/DateFilter';

const FilterAlert = ({ openDrawer, toggleDrawer }) => {
    const drawerWidth = 250;
    const objectList = [
        { name: "Helmet ", value: "helmet", },
        { name: "Glasses", value: "glasses", },
        { name: "Gloves", value: "gloves", },
        { name: "Jackets", value: "jackets", },
        { name: "Shoes", value: "shoes", },
    ]
    const deviceList = [
        { name: "Camera1", value: "camera1", },
    ]
    const locationList = [
        { name: "Room No.1", value: "roomNo1", },
    ]
    return (
        <>
            <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer}
                sx={{
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, padding: 2 },
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Typography fontWeight={600} fontSize={'20px'}>Filter Alert</Typography>
                        <IconButton
                            aria-label="close"
                            onClick={toggleDrawer}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} marginY={'5px'}>
                        <Divider sx={{ borderColor: "gray.main", }} />
                    </Grid>

                    <Grid item xs={12} marginY={2}>
                        <Grid container gap={2}>
                        <Grid item xs={12}>
                                <Typography fontSize={'14px'} fontWeight={600} marginBottom={'6px'}>Date</Typography>
                                <DateFilter />
                            </Grid>
                            <Grid item xs={12}>
                                <CommonSelect labelSelect label='Object' selectList={objectList} />
                            </Grid>
                            <Grid item xs={12}>
                                <CommonSelect labelSelect label='Device' selectList={deviceList} />
                            </Grid>
                            <Grid item xs={12}>
                                <CommonSelect labelSelect label='Location' selectList={locationList} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} marginY={'5px'}>
                        <Divider sx={{ borderColor: "gray.main", }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack flexDirection={'row'} gap={1}>
                            <CommonButton buttonName='Reset' buttonColor='gray' fullWidth onClick={toggleDrawer} />
                            <CommonButton buttonName='Apply' fullWidth onClick={toggleDrawer} />
                        </Stack>
                    </Grid>
                </Grid>
            </Drawer>
        </>
    )
}

export default FilterAlert