import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AddDevice from '../../pages/device/AddDevice';
import ViewDevice from '../../pages/device/ViewDevice';
import ChangePassword from '../../pages/modal/ChangePassword';
import Logout from '../../pages/modal/Logout';
import ExportSuccess from '../../pages/report/ExportSuccess';
import ChangeStatus from '../../pages/alert/ChangeStatus';
import AddProjects from '../../pages/projects/AddProjects';
import ViewProjects from '../../pages/projects/ViewProjects';
import AddPhase from '../../pages/phase/AddPhase';
import ViewPhase from '../../pages/phase/ViewPhase';
import AddTower from '../../pages/towers/AddTower';
import ViewTower from '../../pages/towers/ViewTower';

export default function CommonModal({ handleClose, modalOpen }) {

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="Common_modal"
            open={modalOpen.open}
        >
            {modalOpen.currentComponent === 'addDevice' && <AddDevice handleClose={handleClose} modalOpen={modalOpen}/>}
            {modalOpen.currentComponent === 'viewDevice' && <ViewDevice handleClose={handleClose} modalOpen={modalOpen}/>}
            {modalOpen.currentComponent === 'addProjects' && <AddProjects handleClose={handleClose} modalOpen={modalOpen}/>}
            {modalOpen.currentComponent === 'viewProjects' && <ViewProjects handleClose={handleClose} modalOpen={modalOpen}/>}
            {modalOpen.currentComponent === 'addPhase' && <AddPhase handleClose={handleClose} modalOpen={modalOpen}/>}
            {modalOpen.currentComponent === 'viewPhase' && <ViewPhase handleClose={handleClose} modalOpen={modalOpen}/>}
            {modalOpen.currentComponent === 'addTower' && <AddTower handleClose={handleClose} modalOpen={modalOpen}/>}
            {modalOpen.currentComponent === 'viewTower' && <ViewTower handleClose={handleClose} modalOpen={modalOpen}/>}
            {modalOpen.currentComponent === 'changePassword' && <ChangePassword handleClose={handleClose} modalOpen={modalOpen}/>}
            {modalOpen.currentComponent === 'logout' && <Logout handleClose={handleClose} modalOpen={modalOpen}/>}
            {modalOpen.currentComponent === 'exportSuccess' && <ExportSuccess handleClose={handleClose} modalOpen={modalOpen}/>}
            {modalOpen.currentComponent === 'changeStatus' && <ChangeStatus handleClose={handleClose} modalOpen={modalOpen}/>}
        </Dialog>
    );
}
