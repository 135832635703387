import React from 'react'
import { Logo_black } from '../../helper/Constants'
import { Box, Checkbox, Grid, Typography } from '@mui/material'
import CommonInput from '../../components/common/Input'
import CommonButton from '../../components/common/Button'
import { Link, useNavigate } from 'react-router-dom'

const Signup = () => {
  const navigation = useNavigate();
  return (
    <>
      <Box>
        <Box display={'flex'} alignItems={'center'} padding={1}>
          <img src={Logo_black} width={'160px'} />
        </Box>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
          <Box sx={{ background: '#f1f1f1', margin: '50px 20px', height: '-webkit-fill-available' }} maxWidth={'800px'} width={'100%'} borderRadius={'25px'} padding={{ xs: '40px 20px', sm: '70px' }}>
            <Grid container gap={'20px'} maxWidth={'400px'} width={'100%'} margin={'auto'}>
              <Grid item xs={12}>
                <Typography fontSize={'30px'} fontWeight={'500'} textAlign={'center'} marginBottom={'20px'}>Sign Up</Typography>
              </Grid>
              <Grid item xs={12}>
                <CommonInput placeholder='First Name' />
              </Grid>
              <Grid item xs={12}>
                <CommonInput placeholder='Last Name' />
              </Grid>
              <Grid item xs={12}>
                <CommonInput placeholder='Email' type='email' />
              </Grid>
              <Grid item xs={12}>
                <CommonInput placeholder='Password' passwordInput />
              </Grid>
              <Grid item xs={12}>
                <CommonInput placeholder='Confirm Password' passwordInput />
              </Grid>
              <Grid item xs={12}>
                <Grid container sx={{ justifyContent: 'space-between' }}>
                  <Grid item xs={12}>
                    <Grid container sx={{ alignItems: 'center' }} gap={1}>
                      <Grid item>
                        <Checkbox
                          defaultChecked
                          sx={{
                            padding: '0',
                            color: 'var(--primary)',
                            '& .MuiSvgIcon-root': { fontSize: 22 },
                            '&.Mui-checked': {
                              color: 'var(--primary)',
                            },
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography fontSize={'14px'}>
                          I agree with <Link to='/'>Terms & Conditions</Link> and <Link to='/'>Privacy Policy</Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CommonButton buttonName='Submit' fullWidth onClick={() => navigation('/')} />
              </Grid>
              <Grid item xs={12}>
                <Typography textAlign={'center'}>Already have an Account? <Link to='/'> Log In</Link></Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Signup