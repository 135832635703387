import React from 'react'
import { Box, ListItemText, ListItem, ListItemButton, ListItemIcon, List } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Logo } from '../../helper/Constants';
import { SidebarList } from '../../helper/Helper';

const Sidebar = ({ handleDrawerToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Box width={'250px'}>
      <List sx={{
        padding: '24px 24px 15px 24px',
      }}>
        <img src={Logo} alt='main_logo' width={'160'} /> PPE Kit
      </List>
      {SidebarList.map((data, index) => (
        <List sx={{ borderLeft: ` ${location.pathname === data.path ? '4px solid white !important'
         : location.pathname === '/alert/details' && data.path === '/alert' ? '4px solid white !important' : '4px solid transparent !important'}`, padding: '0' }}>
          <ListItem onClick={() => navigate(data.path)} key={data} disablePadding sx={{ margin: '8px 20px 8px 20px', width: 'unset' }}>
            <ListItemButton onClick={handleDrawerToggle} key={index}
              sx={{
                background: `${location.pathname === data.path ? 'white'
                  : location.pathname === '/alert/details' && data.path === '/alert' ? 'white' : 'black'}`,
                borderRadius: '5px'
              }} className={location.pathname === data.path ? 'active_sidebar'
                : location.pathname === '/alert/details' && data.path === '/alert' ? 'active_sidebar' : ''}>
              <ListItemIcon sx={{ minWidth: 30, width: '13px', height: '17px', }}>
                <img src={location.pathname === data.path ? data.activeIcon 
                : location.pathname === '/alert/details' && data.path === '/alert' ? data.activeIcon : data.icon} alt='' />
              </ListItemIcon>
              <ListItemText primary={data.name} sx={{
                color: `${location.pathname === data.path ? 'black'
                  : location.pathname === '/alert/details' && data.path === '/alert' ? 'black' : 'white'}`
                , fontSize: '15px'
              }} />
            </ListItemButton>
          </ListItem>
        </List>
      ))}
    </Box>
  )
}

export default Sidebar