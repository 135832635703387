import React from "react";
import { Route, Routes } from "react-router-dom";
import './App.css';
import { ThemeProvider } from "@mui/material";
import { theme } from "./helper";
import Layout from "./components/Layout/Layout";
import Login from "./pages/login/Login";
import Signup from "./pages/login/Signup";
import ForgotPassword from "./pages/login/ForgotPassword";

function App() {

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<Layout />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
