import React from 'react'
import { Drawer, Grid, Typography, Divider, Stack, IconButton } from '@mui/material';
import CommonButton from '../../components/common/Button';
import CommonSelect from '../../components/common/Select';
import { CloseIcon } from '../../helper/Icons'
import DateFilter from '../../components/common/DateFilter';

const FilterDevice = ({ openDrawer, toggleDrawer }) => {
    const drawerWidth = 250;
    const nameList = [
        { name: "Entrance", value: "entrance", },
        { name: "Near Elevator", value: "nearElevator", },
        { name: "Roof", value: "roof", },
        { name: "High traffic Area", value: "highTrafficArea", },
    ]
    const ProjectList = [
        { name: "Project 1", value: "project1", },
        { name: "Project 2", value: "project2", },
    ]
    return (
        <>
            <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer}
                sx={{
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, padding: 2 },
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Typography fontWeight={600} fontSize={'20px'}>Add Filter</Typography>
                        <IconButton
                            aria-label="close"
                            onClick={toggleDrawer}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} marginY={'5px'}>
                        <Divider sx={{ borderColor: "gray.main", }} />
                    </Grid>

                    <Grid item xs={12} marginY={2}>
                        <Grid container gap={2}>
                        <Grid item xs={12}>
                                <Typography fontSize={'14px'} fontWeight={600} marginBottom={'6px'}>Date</Typography>
                                <DateFilter />
                            </Grid>
                            <Grid item xs={12}>
                                <CommonSelect labelSelect label='Name' selectList={nameList} />
                            </Grid>
                            <Grid item xs={12}>
                                <CommonSelect labelSelect label='Project Name' selectList={ProjectList} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} marginY={'5px'}>
                        <Divider sx={{ borderColor: "gray.main", }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack flexDirection={'row'} gap={1}>
                            <CommonButton buttonName='Reset' buttonColor='gray' fullWidth onClick={toggleDrawer} />
                            <CommonButton buttonName='Apply' fullWidth onClick={toggleDrawer} />
                        </Stack>
                    </Grid>
                </Grid>
            </Drawer>
        </>
    )
}

export default FilterDevice