import React from 'react'
import { Stack, TextField } from '@mui/material';
import { GrRefresh } from 'react-icons/gr';
import { VscSettings } from 'react-icons/vsc';
import FilterAlert from './FilterAlert';
import AlertTable from './AlertTable';

const Alert = () => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  return (
    <>
      <Stack direction="row" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: '12px' }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px', alignItems: { xs: 'end', sm: 'center' } }}>
        <TextField hiddenLabel className='search_field' placeholder='Search' sx={{ width: { xs: '100%', sm: 'unset' } }} />
        <Stack flexDirection={'row'} alignItems={'center'} gap={'10px'}>
          <VscSettings style={{ fontSize: '20px', cursor: 'pointer' }} onClick={toggleDrawer} />
          <GrRefresh style={{ fontSize: '20px', cursor: 'pointer' }} />
          <FilterAlert openDrawer={openDrawer} toggleDrawer={toggleDrawer} />
        </Stack>
      </Stack>
      <AlertTable/>
    </>
  )
}

export default Alert

