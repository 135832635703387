import React from "react";
import { Chart } from "react-google-charts";

export const data = [
  ["Modules", "Number"],
  ["No Helmet Detection", 50],
  ["No Jacket", 30],
  ["No Gloves", 15],
  ["No Glasses", 15],
];

export const options = {
  title: "",
  pieHole: 0.4,
  is3D: false,
  legend: { position: "bottom" },
  slices: {
    0: { color: '#DBDBDB' },
    1: { color: '#FF9C50' },
    2: { color: '#85D1FC' },
    3: { color: '#EC4D86' },
  }
};

export function ReportChart() {
  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
}
