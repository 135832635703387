import React, { Component } from 'react';
import { Chart } from 'react-google-charts';
import { useStyles } from '../../helper';
import { Box, Select, Stack, Typography } from '@mui/material';
import CommonSelect from '../../components/common/Select';

const AlertChart = () => {
  const classes = useStyles();
  const [selectedData, setSelectedData] = React.useState('data1');

  const handleDataChange = (event) => {
    setSelectedData(event.target.value);
  };

  const data = [
    ['Category', 'Helmet', { role: "style" }],
    ['JUL 18', 20, '#dbdbdb'],
    ['JUL 19', 30, '#FF9C49'],
    ['JUL 20', 40, '#EC4D86'],
    ['JUL 21', 50, '#85D1FC'],
    ['JUL 22', 40, '#72E172'],
    ['JUL 23', 30, '#FF9C49'],
    ['JUL 24', 20, '#85D1FC'],
    ['JUL 25', 30, '#dbdbdb'],
    ['JUL 26', 50, '#EC4D86'],
    ['JUL 27', 70, '#72E172'],
  ];
  const objectList = [
    { name: "Helmet", value: "helmet", },
    { name: "Glasses", value: "glasses", },
    { name: "Gloves", value: "gloves", },
    { name: "Jackets", value: "jackets", },
    { name: "Shoes", value: "shoes", },
  ]
  return (
    <Box className={classes.cardContainer} marginTop={3} padding={{ xs: '16px 10px', sm: 3 }} width={'100%'}>
      {/* <select
        id="dataSelect"
        onChange={handleDataChange}
        value={selectedData}
      >
        <option value="data1">Data 1</option>
        <option value="data2">Data 2</option>
        <option value="data3">Data 3</option>
      </select> */}
      <Stack direction="row" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: '12px' }, flexDirection: { xs: 'column', sm: 'row' }, gap: '8px', alignItems: { xs: 'end', sm: 'center' } }}>
        <Typography fontSize={'20px'} fontWeight={'600'}>Alerts Overtime</Typography>
        <CommonSelect selectList={objectList} style={{ width: '150px' }} className='alert_chart_select'/>
      </Stack>
      <Chart
        width={'100%'}
        height={'300px'}
        chartType="ColumnChart"
        data={selectedData === 'data1' ? data : []}
        options={{
          title: '',
          legend: 'none',
          chartArea: { width: '80%', height: '80%' },
        }}
      />
    </Box>
  );
};

export default AlertChart;
