import {
  Devices, Alert, Camera, Dashboard, Profile, Report, Phase, Projects, Towers,
  Devices_black, Alert_black, Camera_black, Dashboard_black, Profile_black, Report_black,Phase_black, Projects_black, Towers_black,
} from './Constants';

export const SidebarList = [
  {
    name: 'Cameras',
    icon: Camera,
    activeIcon: Camera_black,
    path: '/camera'
  },
  {
    name: 'Devices',
    icon: Devices,
    activeIcon: Devices_black,
    path: '/device'
  },
  {
    name: 'Projects',
    icon: Projects,
    activeIcon: Projects_black,
    path: '/projects'
  },
  {
    name: 'Phase',
    icon: Phase,
    activeIcon: Phase_black,
    path: '/phase'
  },
  {
    name: 'Towers',
    icon: Towers,
    activeIcon: Towers_black,
    path: '/towers'
  },
  {
    name: 'Dashboard',
    icon: Dashboard,
    activeIcon: Dashboard_black,
    path: '/dashboard'
  },
  {
    name: 'Alert',
    icon: Alert,
    activeIcon: Alert_black,
    path: '/alert'
  },
  {
    name: 'Report',
    icon: Report,
    activeIcon: Report_black,
    path: '/report'
  },
  // {
  //   name: 'My Profile',
  //   icon: Profile,
  //   activeIcon: Profile_black,
  //   path: '/profile'
  // },
]