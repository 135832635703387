import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import CommonInput from '../../components/common/Input'
import CommonButton from '../../components/common/Button'
import { CloseIcon } from '../../helper/Icons'

const ChangePassword = ({ handleClose }) => {
    return (
        <div>
            <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
                Change Password
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Grid container gap={2} width={{ xs: '250px', sm: '400px' }} paddingY={1}>
                    <Grid item xs={12}>
                        <CommonInput placeholder='Current Password' passwordInput />
                    </Grid>
                    <Grid item xs={12}>
                        <CommonInput placeholder='New Password' passwordInput />
                    </Grid>
                    <Grid item xs={12}>
                        <CommonInput placeholder='Confirm Password' passwordInput />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton buttonName='Cancel' buttonColor='gray' onClick={handleClose} />
                <CommonButton buttonName='Save' onClick={handleClose} />
            </DialogActions>
        </div>
    )
}

export default ChangePassword