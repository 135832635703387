import React from 'react'
import { Logo_black } from '../../helper/Constants'
import { Box, Checkbox, Grid, Typography } from '@mui/material'
import CommonInput from '../../components/common/Input'
import CommonButton from '../../components/common/Button'
import { Link } from 'react-router-dom'

const ForgotPassword = () => {
  return (
    <>
      <div>
        <Box display={'flex'} alignItems={'center'} padding={1}>
          <img src={Logo_black} width={'160px'} />
        </Box>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'calc(100vh - 75px)'}>
          <Box sx={{ background: '#f1f1f1', margin: '0 20px' }} maxWidth={'800px'} width={'100%'} borderRadius={'25px'} padding={{ xs: '40px 20px', sm: '70px' }}>
            <Grid container gap={'20px'} maxWidth={'400px'} width={'100%'} margin={'auto'}>
              <Grid item xs={12}>
                <Typography fontSize={'30px'} fontWeight={'500'} textAlign={'center'} marginBottom={'15px'}>Forgot password</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography fontSize={'16px'} fontWeight={'500'} textAlign={'center'} marginBottom={'15px'}>Please enter the email address associated
                  with your account below. We will send you a link to reset your password</Typography>
              </Grid>
              <Grid item xs={12}>
                <CommonInput placeholder='Email' type='email' />
              </Grid>
              <Grid item xs={12}>
                <CommonButton buttonName='Submit' fullWidth />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
    </>
  )
}

export default ForgotPassword