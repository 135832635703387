import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { GrRefresh } from 'react-icons/gr'
import DateFilter from '../../components/common/DateFilter'
import { ReportChart } from './ReportChart'
import { useStyles } from '../../helper';
import { Warning } from '../../helper/Constants'
import AlertTable from '../alert/AlertTable'
import AlertChart from './AlertChart'

const Dashboard = () => {
  const classes = useStyles();
  return (
    <>
      <Stack direction="row" justifyContent="flex-end" width='100%' sx={{ marginBottom: { xs: '10px' }, flexDirection: { xs: 'column-reverse', sm: 'row' }, gap: '8px', alignItems: { xs: 'end', sm: 'center' } }}>
        <DateFilter className='date_range_width' />
        <GrRefresh style={{ fontSize: '20px', cursor: 'pointer' }} />
      </Stack>
      <Box className={classes.cardContainer} marginBottom={3}>
        <Stack direction="row" justifyContent="flex-end" width='100%' padding={{ xs: '16px 10px', sm: 3 }} sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: '8px', alignItems: { xs: 'center', sm: 'center' } }}>
          <ReportChart />
          <Box className={classes.cardContainer}>
            <Stack direction="row" justifyContent="center" width='100%' padding={3} sx={{ gap: '12px', alignItems: { xs: 'center' } }}>
              <Box borderRadius={'50%'} padding={3} sx={{ background: '#f1f1f1' }}>
                <img src={Warning} />
              </Box>
              <Box>
                <Typography fontSize={'27px'} fontWeight={'500'} lineHeight={'30px'}>300+</Typography>
                <Typography fontSize={'20px'} fontWeight={'500'} whiteSpace={'nowrap'}>Total Alerts</Typography>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
      <AlertTable/>
      <AlertChart/>
    </>
  )
}

export default Dashboard