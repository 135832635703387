import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { styled } from "@mui/material/styles";
import { Chip, TableCell, TableRow } from '@mui/material';

export const useStyles = makeStyles(({ theme }) => ({
  cardContainer: {
    borderRadius: '15px !important',
    background: '#FFF',
    boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important',
    border: "1.5px solid #fff",
  },
}));

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000000', // Customize your primary color
    },
    secondary: {
      main: '#ffffff', // Customize your primary color
    },
    gray: {
      main: '#f1f1f1', // Customize your primary color
    },
  },
  components: {
    // input ------------
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            padding: '10px 15px',
            border: "1.5px solid #000",
            borderRadius: '6px',
            input: {
              padding: '0'
            }
          },
          '& .Mui-focused': {
            border: "1.5px solid #000",
            outline: "1.5px solid #000",
          },
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: 'transparent !important',
          },
        },
      },
    },
    // input ------------
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '10px 15px',
          border: "1.5px solid #000",
          borderRadius: '6px',
        },
      },
    },
    // searchbar ------------
    MuiStack: {
      styleOverrides: {
        root: {
          '& .search_field': {
            '& .MuiInputBase-root': {
              background: '#f1f1f1',
              boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important',
              padding: '8px 15px',
              border: "1.5px solid #fff",
              borderRadius: '6px',
              input: {
                padding: '0'
              }
            },
            '& .Mui-focused': {
              border: "1.5px solid #fff",
              outline: "1.5px solid #f1f1f1",
            },
          },
        },
      }
    },
    // searchbar ------------
    // button -----------
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          padding: '10px 20px',
          textTransform: 'capitalize',
          fontSize: { xs: '15px', sm: '18px' },
          // width: 'unset',
        },
      },
    },
    // button -----------
    // table -----------
    MuiTable: {
      styleOverrides: {
        root: {
          '& .device_table_img': {
            background: '#f1f1f1',
            padding: '13px',
            borderRadius: '50%',
          }
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            padding: '10px 15px',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          textAlign: 'center',
          '&:first-child': {
            textAlign: 'start',
          },
        },
      },
    },
    // table -----------

    MuiGrid: {
      styleOverrides: {
        item: {
          padding: '0 !important',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#b7b7b7',
        }
      }
    },
  },
});

// table -----------
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#e5e5e500",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "#0f0f0f0a",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#000",
  fontWeight: '700',
}));

export const StyledChip = styled(Chip)(() => ({
  borderRadius: "10px",
  fontSize: "14px",
}));

export const getStatus = (value) => {
  const status = {
    false: {
      title: "Pending",
      styles: {
        backgroundColor: "rgb(235 193 14 / 24%)",
        color: "orange",
      },
    },
    true: {
      title: "Active",
      styles: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
      },
    },

  };
  return status[value];
};

export const getAlertStatus = (value) => {
  const status = {
    false: {
      title: "No",
      styles: {
        backgroundColor: "rgb(235 193 14 / 24%)",
        color: "orange",
      },
    },
    true: {
      title: "Yes",
      styles: {
        backgroundColor: "rgba(18, 183, 106, 0.05)",
        color: "#12B76A",
      },
    },

  };
  return status[value];
};
// table -----------
