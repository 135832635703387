import React from 'react'
import { useStyles } from '../../helper';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Camera1, WhatsappAlertDetails } from '../../helper/Constants';
import CommonInput from '../../components/common/Input';

const AlertDetails = () => {
    const classes = useStyles();

    return (
        <>
            <Typography fontSize={'20px'} fontWeight={600} marginBottom={2}>Details of Alert</Typography>
            <Box className={classes.cardContainer} sx={{ padding: { xs: 3, sm: 5 }, width: { xs: '100' } }}>
                <Stack flexDirection={'row'} gap={{ xs: '30px', sm: '50px' }} sx={{ flexWrap: { xs: 'wrap', lg: 'nowrap' }, justifyContent: 'center' }}>
                    <Grid item xs={3}>
                        <Grid container sx={{ width: { xs: '100%', sm: '200px' } }}>
                            <Grid item xs={12}>
                                <img src={Camera1} width={200} />
                            </Grid>
                            <Grid item xs={12}>
                                Helmet  Detection
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container gap={2} sx={{ width: { xs: '100%', sm: '300px' } }}>
                            <Grid item xs={12}>
                                <CommonInput value='Helmet Setection' labelInput label='Alert Type' disabled={true} />
                            </Grid>
                            <Grid item xs={12}>
                                <CommonInput value='Room No.3' labelInput label='Location' disabled={true} />
                            </Grid>
                            <Grid item xs={12}>
                                <CommonInput value='28th Aug /12:30 PM' labelInput label='Time & Date' disabled={true} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <img src={WhatsappAlertDetails} />
                    </Grid>
                </Stack>
            </Box >
        </>
    )
}

export default AlertDetails