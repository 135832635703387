import * as React from 'react';
import { useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Stack, styled, ListItemText, ListItem, ListItemButton, ListItemIcon, List, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, User_Profile, User, ChangePassword, Logout, } from '../../helper/Constants';
import { HiMenu } from 'react-icons/hi';

import { SidebarList } from '../../helper/Helper';
import CommonModal from '../common/Modal';

const MyComponent = styled('div')({
  borderRadius: '10px',
  background: '#fff',
  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.25) !important',
});

const Header = ({ handleDrawerToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [myprofile, setMyProfile] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = (event) => {
    setMyProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setMyProfile(null);
  };

  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'} borderBottom={'2px solid rgba(217, 217, 217, 0.50)'} paddingBottom={'10px'}>
        <Stack flexDirection={'row'} alignItems={'center'} gap={'10px'}>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <MyComponent
              sx={{ padding: { xs: '8px' }, cursor: 'pointer' }}
              onClick={handleClick}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ p: '0', m: '0' }}
              >
                <HiMenu />
              </IconButton>
            </MyComponent>
          </Box>
          <Box>
            {SidebarList.map((data, index) => {
              return (
                <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
                  {location.pathname === data.path &&
                    <>
                      <Box width={'40px'} height={'40px'} display={'flex'} padding={'10px'} sx={{ background: '#000', borderRadius: '50%' }}>
                        <img src={data.icon} />
                      </Box>
                      <Typography fontSize={'20px'} fontWeight={'500'}>{data.name}</Typography>
                    </>
                  }
                </Stack>
              );
            })}

            {location.pathname === '/alert/details' ?
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
                <Box width={'40px'} height={'40px'} display={'flex'} padding={'10px'} sx={{ background: '#000', borderRadius: '50%' }}>
                  <img src={Alert} />
                </Box>
                <Typography fontSize={'20px'} fontWeight={'500'}>Alert</Typography>
              </Stack>
              : ''}
          </Box>
        </Stack>
        <Box sx={{ cursor: 'pointer' }} onClick={handleClickProfile}>
          <img src={User_Profile} alt='ProfileIcon' width={40} />
        </Box>
        <Menu
          anchorEl={myprofile}
          open={Boolean(myprofile)}
          onClose={handleCloseProfile}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => { navigate('/profile') }} onMouseUp={handleCloseProfile}>
            <Stack flexDirection={'row'} gap={'10px'} >
              <img src={User} />
              <div style={{ display: 'flex' }}>
                My Profile
              </div>
            </Stack>
          </MenuItem>
          <MenuItem
            onClick={() =>
              setIsModalOpen({
                open: true,
                currentComponent: "changePassword",
              })
            }
            onMouseUp={handleCloseProfile}
          >
            <Stack flexDirection={'row'} gap={'10px'}>
              <img src={ChangePassword} />
              <div style={{ display: 'flex' }}>
                Change Password
              </div>
            </Stack>
          </MenuItem>
          <MenuItem onClick={() =>
            setIsModalOpen({
              open: true,
              currentComponent: "logout",
            })
          }
            onMouseUp={handleCloseProfile}
          >
            <Stack flexDirection={'row'} gap={'10px'}>
              <img src={Logout} />
              <div style={{ display: 'flex' }}>
                Logout
              </div>
            </Stack>
          </MenuItem>
        </Menu>
      </Stack>
      <CommonModal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        } />
    </>
  )
}

export default Header