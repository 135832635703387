import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from '../../helper';
import CommonButton from '../../components/common/Button';
import { Camera1, Camera2, Camera3, Camera4 } from '../../helper/Constants'

const camaraList = [
  {
    img: Camera1,
    name: 'Entrance',
  },
  {
    img: Camera2,
    name: 'Near Elevator',
  },
  {
    img: Camera3,
    name: 'Roof',
  },
  {
    img: Camera4,
    name: 'High Traffice Area',
  },
]

const Camera = () => {
  const classes = useStyles();
  return (
    <Stack>
      <Box textAlign={'end'} marginBottom={{ xs: 3, sm: 5}}>
        <CommonButton buttonName='Full Screen' />
      </Box>
      <Grid container gap={{ xs: 2, sm: 3, md: 4 }} maxWidth={'1200px'} margin={'auto'}>
        {
          camaraList.map((data, i) => {
            return (
              <Grid item xs={12} sm={5.7} md={5.7} lg={5.7} key={i}>
                <Box className={classes.cardContainer} padding={{ xs: 2, lg: 3 }}>
                  <Grid container gap={2}>
                    <Grid item xs={12}>
                      <img src={data.img} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '25px' }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography fontSize={'20px'} fontWeight={'500'}>{data.name}</Typography>
                        <CommonButton buttonName='View' />
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )
          })
        }
      </Grid>
    </Stack>
  )
}

export default Camera