import React, { useState } from 'react'
import { Pagination, Paper, Stack, Table, TableCell, TableBody, TableContainer, TableHead, TableRow, TextField, Grid, Select, MenuItem, Box, Menu } from '@mui/material';
import { GrRefresh } from 'react-icons/gr';
import { VscSettings } from 'react-icons/vsc';
import { StyledTableCell, StyledTableRow, useStyles, } from '../../helper';
import { Camera1 } from '../../helper/Constants';
import CommonModal from '../../components/common/Modal';
import FilterReport from './FilterReport';
import CommonButton from '../../components/common/Button';

const Report = () => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  const [myprofile, setMyProfile] = useState(null);

  const handleClickProfile = (event) => {
    setMyProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setMyProfile(null);
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" width='100%' sx={{ marginBottom: { xs: '12px' }, flexDirection: 'column', gap: '8px', alignItems: 'end' }}>
        <Stack flexDirection={'row'} alignItems={'center'} gap={'10px'}>
          <Box>
            <CommonButton buttonName='Export' onClick={handleClickProfile} />
            <Menu
              anchorEl={myprofile}
              open={Boolean(myprofile)}
              onClose={handleCloseProfile}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuItem value='csv'
                onClick={() =>
                  setIsModalOpen({
                    open: true,
                    currentComponent: "exportSuccess",
                  })
                }
                onMouseUp={handleCloseProfile}
              >csv</MenuItem>
              <MenuItem value='excel'
                onClick={() =>
                  setIsModalOpen({
                    open: true,
                    currentComponent: "exportSuccess",
                  })
                }
                onMouseUp={handleCloseProfile}
              >excel</MenuItem>
            </Menu>
          </Box>
          <VscSettings style={{ fontSize: '20px', cursor: 'pointer' }} onClick={toggleDrawer} />
          <GrRefresh style={{ fontSize: '20px', cursor: 'pointer' }} />
          <FilterReport openDrawer={openDrawer} toggleDrawer={toggleDrawer} />
        </Stack>
      </Stack>
      <TableContainer component={Paper} className={classes.cardContainer}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>
                Image
              </StyledTableCell>
              <StyledTableCell>
                Alert Type
              </StyledTableCell>
              <StyledTableCell>
                Device Name
              </StyledTableCell>
              <StyledTableCell>
                Time
              </StyledTableCell>
              <StyledTableCell>
                Date
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 1, 1, 1].map(() => {
              return (
                <StyledTableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: 'pointer' }}
                >
                  <TableCell component="th" scope="row">
                    <img src={Camera1} width={60} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Entrance
                  </TableCell>
                  <TableCell component="th" scope="row">
                    Project 1
                  </TableCell>
                  <TableCell component="th" scope="row">
                    12:30 PM
                  </TableCell>
                  <TableCell component="th" scope="row">
                    28th Aug
                  </TableCell>
                </StyledTableRow>
              )
            })}
            {/* <TableRow>
              <TableCell
                sx={{
                  color: "silver",
                  textAlign: "center",
                  paddingTop: "90px",
                  borderBottom: "none",
                  fontSize: "30px",
                }}
                colSpan="6"
              >
                No records to display
              </TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        sx={{ mt: '10px', pb: 2, fontSize: '14px' }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <div>
          Showing{" "}
          1{" "}
          of 1 Results
        </div>
        {/* <div>
          Showing{" "}
          {page * pagePerSize > totalRecords
            ? totalRecords
            : page * pagePerSize}{" "}
          of {totalRecords} Results
        </div>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
        /> */}
      </Stack>
      <CommonModal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        } />
    </>
  )
}

export default Report
